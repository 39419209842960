<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">Grußwort zur Chanuka-Feier am 29.12.2024</h2>

                <!-- Wrapping the image and text for responsiveness -->
                <div>
                    <img src="assets/img/kayser.jpeg" class="rounded float-md-left mb-3 mr-md-3" height="360"
                        alt="Christine Kayser">

                    <p>Sehr geehrter Rabbiner Eliezer Chitrik,</p>
                    <p>Sehr geehrter Herr Ron Freydmann,</p>
                    <p>Liebe Gemeinde Kehal Adat Jeschurun,</p>
                    <p>Ich freue mich sehr, in Vertretung unseres Oberbürgermeisters Markus König und des Nürnberger
                        Stadtrats, heute bei der Chanuka-Feier dabei zu sein.</p>
                    <p>Es freut mich besonders, dieses Fest heute gemeinsam mit euch allen zu feiern. Deshalb ist dieser
                        Tag
                        auch für mich etwas ganz Besonderes. Chanuka ist in der jüdischen Tradition ein fröhliches,
                        herzliches und familiäres Fest.</p>
                    <p>Liebe Gemeinde, ich danke der Jüdischen Orthodoxen Gemeinde „Kehal Adat Jeschurun“ dafür, dass
                        sie
                        das Entzünden der fünften Kerze für alle Nürnbergerinnen und Nürnberger öffnet – und das seit 20
                        Jahren. Und das, obwohl Chanuka eigentlich eine Feier innerhalb der Familien ist. In vielen
                        jüdischen Familien wird jetzt mit Dreideln gespielt, es wird gesungen und köstliche Speisen
                        werden
                        zubereitet.</p>
                    <p>Genau wie Ihr kenne ich natürlich die Geschichte von den Makkabäern, dem Tempel in Jerusalem und
                        dem
                        Öl für die Menora, das auf wundersame Weise nicht einen, sondern acht Tage gereicht hat. Ein
                        großes
                        Wunder geschah damals. Das Bild der leuchtenden Chanukia kann Hoffnung spenden.</p>
                    <p>Und ein Licht der Hoffnung brauchen wir Menschen, gerade auch Jüdinnen und Juden, dringend. Wir
                        denken dieser Tage besonders an die noch in den Händen der Hamas verbliebenden Geiseln; an die
                        Opfer
                        des Angriffs und deren Angehörige, an alle Opfer des Nahost-Konflikts.</p>

                    <p>Damals geschah ein Wunder, und es wurde mit ihm acht Tage lang der Leuchter entzündet. Im darauf
                        folgenden Jahr legten sie diese Tage fest und machten sie zu Festtagen des Lobes und Dankes.</p>
                    <p>Der Chanuka-Leuchter muss so aufgestellt werden, dass er der Öffentlichkeit ins Auge fällt, denn
                        hinter diesem Gebot steht die Absicht, das Wunder publik zu machen. Ich freue mich, dass wir
                        gleich
                        die fünfte Kerze entzünden werden, und ich wünsche euch und Ihnen allen ein schönes Lichterfest.
                    </p>
                    <p>Ja, wir alle brauchen Wunder und die Wunder kommen, auch wenn wir oft nicht mehr zu hoffen wagen.
                        Hoffnung in die Welt tragen das brauchen wir dringender denn je.</p>
                </div>

                <p>Chanuka Sameach! Ein frohes Lichterfest!</p>
                <br />
                <p>wünscht
                    <br />
                <p style="padding-left: 10px;">Christine Kayser
                    <br />Vorsitzende der SPD-Stadtratsfraktion, Nürnberg
                </p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>