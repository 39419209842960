<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Publikationen</h3>
                <p>Hier finden Sie Material zum Lernen judischer Gesetze und Bräuche:</p>
                <ul>
                    <li>
                        <a
                            href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Slichot-Gesetze+und+Bra%CC%88uche.pdf">Slichot:
                            Gesetze und Bräuche</a>
                    </li>
                    <li>
                        <a
                            href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Hatarat%20Nedarim%20-%20Aufheben%20von%20Gelu%CC%88bden.pdf">Hatarat
                            Nedarim:
                            Aufheben von Gelübden</a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Pessach+Checkliste+-+KAJ.pdf">Pessach
                            Checkliste
                        </a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/netilat-jadaim.pdf">
                            Netilat Jadajim
                        </a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/sfirat-haomer.pdf">
                            Sefirat haOmer
                        </a>
                    </li> </ul>
                <p class="pt-5">Wir freuen uns, Ihnen einen Artikel von Herrn Leibl Rosenberg, Mitglied der IKG Nürnberg
                    und
                    langjähriger, geschätzter und treuer Freund unserer Gemeinde, präsentieren zu dürfen. Dieser
                    Essay befasst sich mit der Nürnberger Adas Israel Gemeinde, die in vielerlei Hinsicht der
                    Vorläufer unserer heutigen Gemeinde ist. Besonders beleuchtet wird dabei das Wirken ihres
                    Rabbiners Dr. Arnold Abraham Jitzchak Klein, seligen Andenkens.<br />
                    Dieser bisher unveröffentlichte Artikel ist ein großzügiges Geschenk von Herrn Rosenberg an
                    unsere Gemeinde und wir geben diesen Aufsatz mit Dankbarkeit unverändert wieder.
                    <br />
                    <a
                        href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Die+Tora+zuerst_Rabbiner+Klein+und+die+Gemeinde+Adas+Israel+in+Nu%CC%88rnberg_Artikel+von+Leibl+Rosenberg.pdf">Die
                        Tora zuerst – Rabbiner Dr. Klein und die Gemeinde Adas Israel in Nürnberg</a>
                </p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>